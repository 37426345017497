// Todo: breakpoint at 625?
import React from 'react'
import Image from 'next/image'

import style from '../Search/Search-Results/Result.module.scss'

import getListingCloudinaryId from 'utils/strings/getListingCloudinaryId'
import cloudinaryLoader from 'utils/imageLoaders/cloudinaryLoader'

import ComingSoon from 'assets/icons/icon-coming-soon-md.svg'

import { AlgoliaListing } from 'types/externalData'

const ListingOverview = ({ listing }: { listing: AlgoliaListing }) => {
  return (
    <div className={`${style.reviewResult}`}>
      <div className={`${style.resultImageReview}`}>
        {listing['ImageUrl'] ? (
          <div className="image">
            <Image
              alt=""
              fill
              loader={cloudinaryLoader}
              sizes="(max-width: 625px) calc(100vw - 32px), 244px"
              src={getListingCloudinaryId(listing['ImageUrl'])}
              style={{
                objectFit: 'cover',
              }}
            />
          </div>
        ) : (
          <div className={style.comingSoon}>
            <ComingSoon className={style.comingSoonImg} />
            <span>Coming Soon!</span>
          </div>
        )}
        <span className={style.resultReviewImageType}>
          {listing['Property Type']}
        </span>
      </div>

      <div className={style.resultReviewDetails}>
        <div className={style.resultDetailsDescription}>
          <span className={style.resultReviewType}>
            {listing['Property Type']}
          </span>
          <div className={style.resultDetailsDescriptionChild}>
            <span className={style.resultReviewHeadline}>
              {listing['Headline']}
            </span>
          </div>
          <span className={style.resultReviewLocation}>
            {listing['City']}, {listing['State']}{' '}
            {listing['Country'] !== 'US' && listing['Country']}
          </span>
        </div>
        <div className={style.resultReviewDetailInfo}>
          <span>Sleeps {listing['Max Occupancy']}</span>
          <span>{listing['Bedrooms']} BR</span>
          <span>{listing['Bathrooms']} BA</span>
        </div>
      </div>
    </div>
  )
}

export default ListingOverview
