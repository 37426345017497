import Image from 'next/image'

import styles from './ListingCard.module.scss'

import getListingCloudinaryId from 'utils/strings/getListingCloudinaryId'
import cloudinaryLoader from 'utils/imageLoaders/cloudinaryLoader'
import getCloudinaryPlaceholder from 'utils/strings/getCloudinaryPlaceholder'

type ListingImageProps = Partial<{
  url: string
  caption: string
  propertyType: string
}>

const ListingImage = ({ url, caption, propertyType }: ListingImageProps) => {
  return (
    <div className={styles.listing__image}>
      {url ? (
        <div className="is-16by9-desktop is-3by2-mobile">
          <Image
            alt={caption ?? ''}
            blurDataURL={getCloudinaryPlaceholder(getListingCloudinaryId(url))}
            fill
            loader={cloudinaryLoader}
            loading="lazy"
            placeholder="blur"
            sizes="(max-width: 600px) calc(100vw - 3rem), 320px"
            src={getListingCloudinaryId(url)}
            style={{
              objectFit: 'cover',
            }}
          />
        </div>
      ) : (
        <span className={styles.unavailable__img}>Image not available</span>
      )}
      <div className={styles.type__label}>{propertyType}</div>
    </div>
  )
}

export default ListingImage
